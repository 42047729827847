<template>
    <div class="w-full h-full flex items-center justify-center" :style="'background-image: url(/img/bckimg.jpg); background-repeat: no-repeat; background-size: cover; background-position: center;'">

        <div class="h-auto w-full bg-lightgray rounded-lg shadow-shadowcard max-w-lg overflow-hidden relative">

            <div class="px-2 pt-2 mt-4 flex flex-row justify-center items-center relative">
                <i class="mdi mdi-arrow-left ml-4 text-red absolute top-0 left-0" @click="goback()"></i>
                <img class="mx-auto w-auto h-16" alt="Vue logo" src="/img/logotextred.png">
            </div>

            <Request
                     model="Auth"
                     action="passwordresset"
                     :form="form"
                     @success="onSuccess"
                     @error="onError"
                     v-slot="{ request, loading }" class="w-full">

                <div
                     @keypress.enter="request"
                     class="mt-2 w-full py-4 rounded-lg flex flex-col justify-center items-center">

                    <label class="block w-10/12">
                        <span class="text-sm -mb-1">Usuario</span>
                        <input type="email" v-model="form.email" class="w-full mt-1 form-input py-1 border-0 text-gray" :class="{'border border-red': error}" placeholder="Email">
                    </label>
                    <label class="block w-10/12">
                        <span class="text-sm -mb-1">{{ $t('password') }} <i class="mdi mdi-information-outline text-blue" @click="errorpass = !errorpass"></i></span>
                        <input type="password" v-model="form.password" class="w-full mt-1 form-input py-1 border-0 text-gray" :class="{'border border-red': error}" :placeholder="$t('password')">
                    </label>
                    <label class="block w-10/12">
                        <span class="text-sm -mb-1">{{ $t('confirmPassword') }}</span>
                        <input type="password" v-model="form.password_confirmation" class="w-full mt-1 form-input py-1 border-0 text-gray" :class="{'border border-red': error}" :placeholder="$t('confirmPassword')">
                    </label>
                    <div class="mt-4 w-5/12 shadow">
                        <button
                                @click="request"
                                class="w-full px-4 py-1 bg-red font-medium text-white rounded-lg"
                                :class="{ 'opacity-50': loading }"
                                :disabled="loading">
                            {{$t('updatePassword')}}
                        </button>
                    </div>
                </div>

            </Request>
            <div class="w-full py-4 text-gray text-center text-sm" v-if="show">
                Tú contraseña ha sido actualizada.
            </div>

            <div class="h-auto py-2 bg-darkgray2 flex flex-row justify-center items-center">
                <span class="text-2xs font-thing text-gray">Powered by Zeus</span>
            </div>

            <div v-if="errorpass" class="absolute h-full w-full top-0 left-0 mx-auto flex col justify-center items-center" style="backdrop-filter:blur(3px)">
                <div class="h-auto w-4/5 rounded-lg shadow-card flex flex-col overflow-hidden" :class="'bg-module-whitemode'">
                    <div class="h-10 w-full bg-red flex flex-row justify-between items-center px-2">
                        <span class=" font-semibold text-white">{{$t('passwordMustBe')}}</span>
                        <div class="h-10 w-10 flex flex-col justify-center items-center" @click="errorpass = !errorpass">
                            <i class="mdi mdi-close text-white"></i>
                        </div>
                    </div>
                    <div class="flex-1 flex flex-col justify-center items-start p-4">
                        <span :class="'text-dfont'"><span class="font-bold">·</span> {{$t('betweenCharacters')}}</span>
                        <span :class="'text-dfont'"><span class="font-bold">·</span> {{$t('leastOneDigit')}}</span>
                        <span :class="'text-dfont'"><span class="font-bold">·</span>{{$t('leastLowerAndUpperCase')}}</span>
                    </div>
                </div>
            </div>

        </div>

    </div>

</template>

<script>
    import { Request } from '@/api/components';
    import { actions } from '@/store';

    export default {
        name: 'Login',
        components: {
            Request
        },
        data() {
            return {
                email: '',
                error: false,
                show:false,
                password:'',
                confirmpassword:'',
                url:window.location.origin,
                form:{
                    email:this.$route.query.email,
                    password:'',
                    password_confirmation:'',
                    token:this.$route.query.token
                },
                errorpass: false
            };
        },
        methods: {
            onSuccess(response) {
                this.show=true;
                actions.setToken(response.data);
                this.$router.push({name : '/group'});
            },
            onError(error) {

                this.error = true

            },
            goback(){
                this.$router.go(-1)
            },
        },
        mounted(){
            
        },

        
    }
</script>

